<template>
  <div class="h-100 w-100">
    <transition mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup() {},
});
</script>

<style lang="scss" scoped></style>
